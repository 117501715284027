<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      style="padding: 20px 30px; width: calc(100% - 335px)"
      class="absolute"
      :title="__('DNIS Pool')"
    />
    <el-button
      :disabled="!this.canWrite()"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create DNIS Pool") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :auto-refresh-content="!this.modalCoverContent"
          :refresh-interval="5000"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer" v-loading="loading">
          <el-table
            ref="dnisPoolTable"
            class="list-table"
            :data="dnispool"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            v-show="canRead()"
          >
            <el-table-column :label="__('Name')" prop="pool_name">
            </el-table-column>
            <el-table-column
              :label="__('Total Numbers')"
              prop="total_numbers_count"
            />
            <el-table-column
              :label="__('Available Numbers')"
              prop="available_numbers_count"
            />
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "DNISPoolsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["pool_name", "pool_id"],
      fetchedAccounts: false,
      search: "",
      filters: ["pool_name"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  computed: {
    ...mapState("dnispool", {
      dnispool: state => state.dnispool,
      loading: state => state.loading
    })
  },
  methods: {
    ...mapActions("dnispool", {
      contentAPI: "getDnisPools",
      deleteContentMethod: "deleteDnisPool",
      undoDeleteContent: "undoDeleteDnisPool"
    }),

    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },

    handleClear() {
      this.$refs.dnisPoolTable && this.$refs.dnisPoolTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleDeleteItem(index, account) {
      this.handleDelete(account);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.dnisPoolTable &&
            this.$refs.dnisPoolTable.setCurrentRow(this.dnispool[val]);
        } else if (val === -1) {
          this.$refs.dnisPoolTable && this.$refs.dnisPoolTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
.rounded-menu-icon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
}
</style>
